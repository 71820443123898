.containerDates {
    font-family: sans-serif;
}

.datesDates {
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-top: 3rem;
}

.titleDates {
    color: navy;
    font-weight: 800;
    margin: 0;
}

.card-containerDates {
    width: 3rem;
    height: 3rem;
    position: relative;
    margin-left: 1rem;
    margin-right: 1rem;
}

.cardDates {
    overflow: hidden;
    box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.2);
    padding: 2rem;
    border-radius: 1rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    cursor: pointer;
}

.expandedDates {
    width: 10rem;
    height: 10rem;
    background-color: navy;
    position: relative;
    left: -100%;
    top: 150%;
}

.secondaryDates {
    background-color: white;
    height: 2.5rem;
    padding: 0.5rem;
    justify-content: center;
    width: 8rem;
    margin-left: 1rem;
}

.compactDates {
    width: 3rem;
    height: 3rem;
    padding: 0.5rem;
    border-radius: 1.5rem;
}

.expandedDates .titleDates {
    font-size: 5em;
    color: white;
}

.compactDates .titleDates {
    font-size: 1.5em;
}