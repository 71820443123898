@import url("https://fonts.googleapis.com/css?family=Josefin+Sans:300,400,600,700&display=swap");
@import "./varibles.scss";
@import "./breakpoints.scss";
@import "./header.scss";
@import "./home/banner.scss";
@import "./home/cases.scss";
@import "./navigation.scss";
body,
html {
    visibility: hidden;
    user-select: none;
    background: $white;
    overscroll-behavior: none;
    margin: 0;
    font-family: "Josefin Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// h1,
// h2,
// h3,
// p {
//     font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
// }
.containerHome {
    width: 1560px;
    min-width: 1560px;
    margin: 0 auto;
    height: 100%;
    @include media("<=1560px") {
        width: 1280px;
        min-width: 1280px;
    }
    @include media("<=desktop") {
        width: 1080px;
        min-width: 1080px;
    }
    @include media("<=1080px") {
        width: 100%;
        min-width: 100%;
    }
    .rowHome {
        display: flex;
        height: 100%;
        padding: 0 32px;
        @include media("<=phone") {
            padding: 0 16px;
        }
    }
    .v-centerHome {
        align-items: center;
    }
    .space-betweenHome {
        justify-content: space-between;
    }
}

.container-fluidHome {
    width: 100%;
    height: 100%;
    .rowHome {
        display: flex;
        height: 100%;
    }
}

// intro overlay
.intro-overlayHome {
    .topHome {
        height: 50vh;
        height: calc(var(--vh, 1vh) * 50);
        position: absolute;
        width: 100%;
        z-index: 8;
        .overlay-topHome {
            position: absolute;
            height: 100%;
            width: 33.333vw;
            background: $black;
            bottom: 0;
            left: 0;
            right: 0;
            @include media("<=tablet") {
                width: 100vw;
            }
            &:nth-child(2) {
                left: 33.333%;
                @include media("<=tablet") {
                    display: none;
                }
            }
            &:nth-child(3) {
                left: 66.666%;
                @include media("<=tablet") {
                    display: none;
                }
            }
        }
    }
    .bottomHome {
        height: 874.5vh;
        // height: calc(var(--vh, 1vh) * 50);
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 8;
        .overlay-bottomHome {
            position: absolute;
            height: 100%;
            width: 33.333vw;
            background: $black;
            bottom: 0;
            right: 66.666%;
            @include media("<=tablet") {
                right: 0;
                width: 100vw;
            }
            &:nth-child(2) {
                right: 33.333%;
                @include media("<=tablet") {
                    width: 100vw;
                    top: 100%;
                    right: 0;
                }
            }
            &:nth-child(3) {
                right: 0;
                @include media("<=tablet") {
                    width: 100vw;
                    top: 200%;
                }
            }
        }
    }
}

.AppHome {
    z-index: 2;
    transform: translateY(0);
    position: relative;
}

.pageHome {
    height: 100vh;
    width: 100%;
    background: $white;
    .rowHome {
        display: flex;
        align-items: center;
    }
}