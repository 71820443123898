$text: #e85a4f;
$secondary: #a09da6;
$other:#41b3a3;
// html,
// body {
//     font-family: "Avenir", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
// }
.testimonial-sectionChoose {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    &:after {
        content: "";
        position: absolute;
        width: 900px;
        height: 550px;
        background: #f2f2f6;
        right: 0;
        bottom: 0;
        opacity: 0.8;
        z-index: -10;
    }
    .testimonial-containerChoose {
        width: 1280px;
        min-width: 1280px;
        height: 600px;
        position: relative;
        .arrowsChoose {
            position: absolute;
            display: flex;
            width: 100px;
            align-items: center;
            justify-content: center;
            bottom: 0;
            top: 0;
            cursor: pointer;
            border-radius: 8px;
            transition: 0.3s ease-in-out;
            &:hover {
                box-shadow: 0px 0px 30px rgba(0, 0, 80, 0.05);
            }
            &.rightChoose {
                right: 0;
            }
        }
        .innerChoose {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 600px;
            .t-imageChoose {
                width: 500px;
                height: 600px;
                display: flex;
                justify-content: center;
                align-items: center;
                &:after {
                    content: "";
                    position: absolute;
                    width: 200px;
                    height: 200px;
                    background: #c94d44;
                    left: 140px;
                    top: 0;
                    border-radius: 100%;
                    z-index: -9;
                }
                ul {
                    display: flex;
                    position: absolute;
                    overflow: hidden;
                    height: 460px;
                    width: 340px;
                    box-shadow: 0px 0px 40px rgba(0, 0, 10, 0.25);
                    li {
                        height: 460px;
                        width: 340px;
                        img {
                            height: 460px;
                            width: 340px;
                            // flex-shrink: 0;
                        }
                    }
                }
            }
            .t-contentChoose {
                width: 500px;
                height: 600px;
                display: flex;
                align-items: center;
                ul {
                    position: absolute;
                    overflow: hidden;
                    width: 500px;
                    height: 400px;
                    li {
                        width: 500px;
                        height: 400px;
                        color: $text;
                        display: flex;
                        align-items: center;
                        position: absolute;
                        opacity: 0;
                        &:nth-child(1) {
                            &:after {
                                content: "";
                                position: absolute;
                                height: 12px;
                                width: 310px;
                                background: $text;
                                opacity: 0.3;
                                left: 0;
                                top: 206px;
                            }
                        }
                        &:nth-child(2) {
                            &:after {
                                content: "";
                                position: absolute;
                                height: 12px;
                                width: 148px;
                                background: $text;
                                opacity: 0.3;
                                left: 54px;
                                top: 126px;
                            }
                        }
                        &:nth-child(3) {
                            &:after {
                                content: "";
                                position: absolute;
                                height: 12px;
                                width: 280px;
                                background: $text;
                                opacity: 0.3;
                                left: 144px;
                                top: 166px;
                            }
                        }
                        // &:nth-child(4) {
                        //     &:after {
                        //         content: "";
                        //         position: absolute;
                        //         height: 12px;
                        //         width: 280px;
                        //         background: $text;
                        //         opacity: 0.3;
                        //         left: 144px;
                        //         top: 166px;
                        //     }
                        // }
                        // &:nth-child(5) {
                        //     &:after {
                        //         content: "";
                        //         position: absolute;
                        //         height: 12px;
                        //         width: 280px;
                        //         background: $text;
                        //         opacity: 0.3;
                        //         left: 144px;
                        //         top: 166px;
                        //     }
                        // }
                        // &:nth-child(6) {
                        //     &:after {
                        //         content: "";
                        //         position: absolute;
                        //         height: 12px;
                        //         width: 280px;
                        //         background: $text;
                        //         opacity: 0.3;
                        //         left: 144px;
                        //         top: 166px;
                        //     }
                        // }
                        // &:nth-child(7) {
                        //     &:after {
                        //         content: "";
                        //         position: absolute;
                        //         height: 12px;
                        //         width: 280px;
                        //         background: $text;
                        //         opacity: 0.3;
                        //         left: 144px;
                        //         top: 166px;
                        //     }
                        // }
                        .content-innerChoose {
                            width: 500px;
                            .quoteChoose {
                                font-size: 26px;
                                letter-spacing: 0.88px;
                                line-height: 40px;
                                font-weight: 700;
                                margin-bottom: 16px;
                            }
                            .nameChoose {
                                font-size: 18px;
                                letter-spacing: 0.88px;
                                line-height: 28px;
                                color: $secondary;
                                font-weight: 600;
                            }
                            .titleChoose {
                                font-size: 18px;
                                letter-spacing: 0.88px;
                                line-height: 28px;
                                color: $other;
                            }
                        }
                    }
                }
            }
        }
    }
}