@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,600,800&display=swap");
$red: #e20001;
$black: #191919;
// html,
// body {
//     padding: 0;
//     margin: 0;
//     font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
// }
.containerLG {
    width: 1280px;
    min-width: 1280px;
    margin: 0 auto;
    .wrapperLG {
        padding: 0 48px;
        .homeLG {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            p {
                font-size: 0.75rem;
                letter-spacing: 0.3rem;
                text-transform: uppercase;
                font-weight: 500;
            }
            h5 {
                margin-top: 260px;
                font-size: 24px;
                font-weight: 600;
                padding-right: 360px;
            }
        }
    }
}

.headerLG {
    height: 758px;
    .inner-headerLG {
        position: relative;
        // z-index: 10;
        height: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .logoLG a {
            font-weight: 700;
            font-size: 1rem;
            text-decoration: none;
            color: $black;
        }
        .menuLG {
            button {
                border: none;
                background: none;
                outline: none;
                cursor: pointer;
                font-size: 0.8rem;
                mix-blend-mode: difference;
            }
        }
    }
    .hamburger-menuLG {
        // display: none;
        // z-index: 9;
        top: 0;
        left: 0;
        right: 0;
        position: fixed;
        height: 100%;
        width: 100%;
        .menu-secondary-background-colorLG {
            background: $black;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            position: fixed;
            height: 100%;
            width: 100%;
            // z-index: -1;
        }
        .menu-layerLG {
            position: relative;
            background: $red;
            height: 100%;
            overflow: hidden;
            .menu-city-backgroundLG {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                position: absolute;
                height: 100%;
                width: 100%;
                opacity: 0;
                background-size: cover;
                background-repeat: no-repeat;
                animation-name: backgroundEffect;
                animation-duration: 30s;
                animation-iteration-count: infinite;
            }
            .wrapperLG {
                position: relative;
                .menu-linksLG {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    position: relative;
                    top: 150px;
                    .navLG {
                        display: block;
                        ul {
                            margin: 0;
                            padding: 0;
                            li {
                                list-style: none;
                                font-size: 5rem;
                                font-weight: 700;
                                cursor: pointer;
                                height: 135px;
                                overflow: hidden;
                                position: relative;
                                width: 700px;
                                a {
                                    position: absolute;
                                    color: #fff;
                                    text-decoration: none;
                                    &:hover {
                                        color: $black;
                                    }
                                }
                            }
                        }
                    }
                    .infoLG {
                        color: #fff;
                        width: 300px;
                        h3 {
                            font-size: 2.5rem;
                            margin: 8px auto;
                        }
                        p {
                            margin: 0 auto;
                            font-size: 1.1rem;
                            letter-spacing: 0.5px;
                        }
                    }
                }
                .locationsLG {
                    position: absolute;
                    bottom: -15px;
                    color: #fff;
                    margin-top: 12px;
                    font-size: 1rem;
                    font-weight: 600;
                    span {
                        cursor: pointer;
                        &:first-child {
                            margin-left: 64px;
                        }
                        font-weight: 400;
                        margin: 0 32px;
                        transition: 0.3s ease-in-out;
                        &:hover {
                            color: #fff;
                            background: $black;
                            padding: 8px 24px;
                            border-radius: 4px;
                        }
                    }
                }
            }
        }
    }
}

@keyframes backgroundEffect {
    0% {
        background-position: 0% 0%;
    }
    25% {
        background-position: 40% 10%;
    }
    50% {
        background-position: 0 10%;
    }
    75% {
        background-position: 10% 40%;
    }
    100% {
        background-position: 0% 0%;
    }
}